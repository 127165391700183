@use "uswds-core" as *;
@use "./assets/uswds/_variables" as *;

.usa-logo a {
  color: $primary-acf-blue;
}

.usa-nav__primary > .usa-nav__primary-item > a:hover {
  color: $accent-magenta;
}

.usa-header--extended .usa-nav__primary-item > .usa-current::after, 
.usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover::after {
  background-color: $accent-magenta;
}

.usa-nav__secondary-item a {
  color: $primary-graphite;
  &:hover {
    color: $accent-magenta;
  }
}

.usa-menu-btn, .usa-menu-btn:hover {
  background-color: $primary-medium-blue;
}

.usa-nav__primary > .usa-nav__primary-item > a {
  font-weight: 400;
  color: $primary-graphite;
}

.usa-nav__primary > .usa-nav__primary-item > .usa-current {
  font-weight: 700;
}

.usa-nav__wide {
  align-items: center;
  border-color: #dfe1e2;
  display: flex;
}