@use "uswds-core" as *;
@use "./assets/uswds/_variables" as *;

.usa-button {
  font-size: 1rem;
}

.usa-hero .usa-button {
  line-height: 1.5;
}

.usa-button,
.usa-button:hover {
  background-color: $primary-medium-blue;
}

.usa-button--outline {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px $primary-medium-blue;
    color: $primary-medium-blue;
}

.usa-button--outline.usa-button--hover, .usa-button--outline:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #1a4480;
    color: #1a4480;
}

.button-anchor {
  text-decoration: none
}
