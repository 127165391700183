@forward "src/assets/uswds/_uswds-theme-general";

@forward "uswds";

@forward 'src/assets/App';
@forward 'src/assets/GovBanner';
@forward 'src/assets/Header';
@forward 'src/assets/SplashPage';
@forward 'src/assets/Profile';
@forward 'src/assets/Footer';
@forward 'src/assets/Modal';
@forward 'src/assets/Button';
@forward 'src/assets/FileUpload';
@forward 'src/assets/Reports';

h1:focus {
  outline: none !important;
}