@use "uswds-core" as *;
@use "./assets/uswds/_variables" as *;

// There are some minor changes that need to be made before releasing beta,
// these are captured in issue #276: https://github.com/raft-tech/TANF-app/issues/276

.resources-header {
  color: $primary-graphite;
}

.usa-hero {
  padding: 20px;
}

.usa-hero1 {
  background-image: url("./hero1.jpg");
}

.usa-hero2 {
  background-image: url("./hero2.jpg");
}

.usa-hero3 {
  background-image: url("./hero3.jpg");
}

.usa-hero__callout {
  background-color: $primary-light-pale-blue;
}

.usa-hero__heading--alt {
  color: $primary-acf-blue;
}

.sign-in-button,
.sign-in-button:hover {
  background-color: $primary-medium-blue;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.usa-hero__callout {
  box-shadow: 0 0 29px 0 rgb(0, 0, 0.5);
  border-radius: 0.25rem;
}

@media screen and (max-width: 640px) {
  .resource-info__secondary {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .sign-in-button {
    flex-direction: column;
    align-content: center;
    margin: auto;
  }
}
