@import 'theme/_global.scss';

html, body, div#root {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.usa-banner__header {
  display: none;
}

main#main-content {
  min-height: calc(100% - 239px);
  padding: 0;
  margin: 0;
}
