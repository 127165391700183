@use "uswds-core" as *;
@use "./assets/uswds/_variables" as *;
@use 'include-media/dist/include-media' as *;

@include media(">=tablet") {
  .usa-banner {
    background-color: #ebf3fa;
    &__header {
      display: block;
      background-color: $gov-banner-background;
      &-text {
        color: white;
      }
    }
    &__button {
      &-text {
        color: white;
      }
      &::after {
        background-color: white;
      }
      &:hover {
        &::after {
          background-color: white;
        }
      }
    }
    &__button[aria-expanded=true]::after,
    &__button[aria-expanded=true]:hover::after {
      background-color: white;
    }
  }
}